import React from 'react'
import { Link } from 'react-router-dom';
import {useStateContext } from "../context/ContextProvider";

const NotFound = () => {  

  const { isLogin } = useStateContext();

  return (
    <div className={`mx-16 ${isLogin ? "my-10 h-[calc(100vh-9.5rem)]" : "h-screen"} rounded-lg flex justify-center items-center`}>
      <div className="rounded-lg bg-white p-8 text-center shadow-xl">
        <h1 className="mb-4 text-4xl font-bold">404 Not Found</h1>
        <p className="text-gray-600">Oops! The page you are looking for could not be found.</p>
        <Link to={isLogin ? "/" : "/login"} className="mt-4 inline-block rounded bg-blue-500 px-4 py-2 font-semibold text-white hover:bg-blue-600">Go back to {isLogin ? "Home" : "Login"}</Link></div>
    </div>
  )
}

export default NotFound;