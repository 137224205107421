import { Skeleton } from '@mui/joy';
import React from 'react'
const UserTrackSkeleton = ({ colLen }) => {
  return (
    Array(5).fill().map((item, index) => (
      <>
        <div key={index} className='flex justify-between items-center my-2 mx-6'>
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="rectangular" width={180} height={40} />
          <Skeleton variant="rectangular" width={130} height={40} />
          <Skeleton variant="rectangular" width={200} height={40} />
          <Skeleton variant="rectangular" width={130} height={40} />
          <Skeleton variant="rectangular" width={130} height={40} />
        </div>
        <hr />
      </>
    ))
  )
};

export default UserTrackSkeleton