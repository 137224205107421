import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Tooltip, Typography } from '@mui/joy';
import { Box, Container, Button } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import finalUrl from '../../../config/config';
import { NoDataFound } from '../../common';
import { EmployeeTableSkeleton } from '../../common/Skeletons';

const columns = [
  {
    field: 'profile', headerClassName: "header", headerName: 'Profile', sortable: false, headerAlign: 'center', width: 100, disableColumnMenu: true,
    renderCell: (params) => (
      <Tooltip title="View Profile" arrow placement='right' variant='solid' color='primary'>
        <Link to={`/user/${params.row.uid}`} className='w-10 h-10 bg-blue-400 mx-auto flex justify-center items-center my-[5px] rounded-full font-semibold text-white hover:shadow-md'>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  { field: 'id', headerClassName: "header", headerName: 'Employee ID', width: 120 },
  { field: 'firstName', headerClassName: "header", headerName: 'First Name', width: 100 },
  { field: 'lastName', headerClassName: "header", headerName: 'Last Name', width: 100 },
  { field: 'role', headerClassName: "header", headerName: 'Role', width: 100 },
  { field: 'age', headerClassName: "header", headerName: 'Age', width: 80 },
  {
    field: 'rating', headerClassName: "header", headerName: 'Rating', width: 100,
    renderCell: (params) => (
      <span className='ml-4'>{params.value}</span>
    )
  },
  {
    field: 'isEnrolled', headerClassName: "header", headerName: 'Enrolled', width: 120,
    renderCell: (params) => (
      <div className='w-full h-full flex justify-start items-center'>
        <div className={`flex justify-center items-center gap-3 border pl-5 pr-4 ${params.value ? "border-green-400" : "border-red-400"} rounded-2xl`}>
          <Badge color={params.value ? "success" : "danger"} size="sm" />
          <Typography variant="caption" className={`${params.value ? "!text-green-400" : "!text-red-400"}`}>{params.value ? 'Yes' : 'No'}</Typography>
        </div>
      </div >
    )
  },
  {
    field: 'isActive', headerClassName: "header", headerName: 'Active', width: 130, flex: 1,
    renderCell: (params) => (
      <div className='w-full h-full flex justify-start items-center'>
        <div className={`flex justify-center items-center gap-3 border px-2 pl-3 ${params.value ? "border-green-400" : "border-red-400"} rounded-2xl`}>
          <Badge color={params.value ? "success" : "danger"} size="sm" />
          <Typography variant="caption" className={`${params.value ? "!text-green-400" : "!text-red-400"}`}>{params.value ? 'Active' : 'Inactive'}</Typography>
        </div>
      </div>
    )
  },

  {
    field: 'Enroll', headerClassName: "header", headerName: 'Enroll', width: 100, headerAlign: 'center',
    sortable: false, disableColumnMenu: true,
    renderCell: (params) => (
      <div className='w-full h-full flex justify-center items-center'>
        <Link to={`/trackselect/${params?.row.id}`}>
          <Button variant="contained" size="small" style={{ color: 'white' }}>
            Enroll
          </Button>
        </Link>
      </div>
    )
  },
];

const EmployeeList = () => {

  const [employeesData, setEmployeesData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const url = finalUrl + '/employees';
        const response = await axios.get(url);
        setEmployeesData(response.data);
        setLoading(true);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUsers();

  }, []);

  const updatedEmployeesData = employeesData.map((user) => ({
    uid: user.uid,
    id: user.eid,
    lastName: user.lastName,
    firstName: user.firstName,
    age: user.age,
    role: user.role,
    rating: user.rating,
    isActive: user.isActive,
    isEnrolled: user.isEnrolled,
    profile: user.firstName[0].toUpperCase(),
  }));

  return (
    <div className='mx-5 my-6 pt-2 pb-6 flex flex-col justify-start gap-3 relative transition-all ease-in-out duration-500 bg-white rounded-lg overflow-y-hidden min-w-[calc(100vw-18.5rem)] min-h-screen'>
      <div className='mb-4 px-6'>
        <Typography variant='h1' fontSize={24} className='font-bold'>All Employees</Typography>
        <span className='text-sm text-slate-400'>List of all employess working with us.</span>
      </div>
      <div className='absolute top-24 right-0 bottom-0 left-0'>
        <Container className='w-full'>
          <Box sx={{ height: 490, width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <DataGrid
              disableAutosize
              rows={updatedEmployeesData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30]}
              slots={{
                noRowsOverlay: () => (
                  loading ? <EmployeeTableSkeleton /> : updatedEmployeesData.length === 0 ? <NoDataFound /> : null
                ),
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}

              disableColumnResize

              sx={{
                '& .header': {
                  backgroundColor: '#0ea5e9',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '15px'
                },
              }
              }
            />
          </Box>
        </Container>

      </div>
    </div>
  );
}

export default EmployeeList;
