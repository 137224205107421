import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Tooltip, Typography } from '@mui/joy';
import { Box, Button, Container } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import finalUrl from '../../../config/config';
import { NoDataFound } from '../../common';
import { UserTableSkeleton } from '../../common/Skeletons';

const formatDate = (date) => {
  const d = new Date(date);
  return d.toISOString().split('T')[0];
}

const columns = [
  {
    field: 'profile', headerClassName: "header", headerName: 'Profile', sortable: false, headerAlign: 'center', width: 90, disableColumnMenu: true,
    renderCell: (params) => (
      <Tooltip title="View Profile" arrow placement='right' variant='solid' color='primary'>
        <Link to={`/user/${params.row.uid}`} className='w-10 h-10 bg-blue-400 mx-auto flex justify-center items-center my-[5px] rounded-full font-semibold text-white hover:shadow-md'>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  { field: 'fullname', headerClassName: "header", headerName: 'Full Name', width: 180 },
  { field: 'id', headerClassName: "header", headerName: 'User ID', width: 130 },
  { field: 'age', headerClassName: "header", headerName: 'Age', width: 100 },
  { field: 'gender', headerClassName: "header", headerName: 'Gender', width: 120 },
  { field: 'city', headerClassName: "header", headerName: 'City', width: 100 },
  {
    field: 'registeredOn', headerClassName: "header", headerName: 'Registered On', width: 100, flex: 1,
    renderCell: (params) => (
      <span>{formatDate(params.value)}</span>
    )
  },
  {
    field: 'isEmployee',
    headerName: 'Employee',
    headerClassName: "header",
    headerAlign: 'center',
    width: 150,
    renderCell: (params) => (
      <div className='w-full h-full flex justify-center items-center'>
        {
          params.value === false ? (
            <Link to={`/usrtoemp/${params.row.id}`}>
              <Button variant="contained" size="small" style={{ color: 'white' }}>
                Hire
              </Button>
            </Link>
          ) : (
            <div className='flex justify-center items-center gap-3 border px-2 pl-3 border-green-400 rounded-2xl'>
              <Badge color="success" size="sm" />
              <Typography variant="caption" className='!text-green-600'>Hired</Typography>
            </div>
          )}
      </div>
    ),
  }
];

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const url = finalUrl + '/users';
        const response = await axios.get(url);
        setUsers(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUsers();

  }, []);

  const updatedUserData = users.map((user) => ({
    uid: user.uid,
    id: user.uid,
    fullname: user.fullName,
    gender: user.gender,
    city: user.city,
    age: user.age,
    registeredOn: user.registeredOn,
    isEmployee: user.isEmployee,
    profile: user.fullName[0].toUpperCase()
  }));

  return (
    <div className='mx-5 my-6 pt-2 pb-6 flex flex-col justify-start gap-3 relative transition-all ease-in-out duration-500 bg-white rounded-lg overflow-y-hidden min-h-screen'>
      <div className='mb-4 px-6'>
        <Typography variant='h1' fontSize={24} className='font-bold'>View All Users</Typography>
        <span className='text-sm text-slate-400'>List of all users those are connected with us.</span>
      </div>
      <div className='absolute top-24 right-0 bottom-0 left-0'>
        <Container className='w-full'>
          <Box sx={{ height: 490, width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <DataGrid
              disableAutosize
              rows={updatedUserData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30]}
              slots={{
                noRowsOverlay: () => (
                  loading ? <UserTableSkeleton /> : updatedUserData.length === 0 ? <NoDataFound /> : null
                ),
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}

              disableColumnResize

              sx={{
                '& .header': {
                  backgroundColor: '#0ea5e9',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '15px'
                },
              }
              }
            />
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default UsersList;