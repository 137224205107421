import React, { createContext, useContext, useState } from 'react';

const StateContext = createContext();

const initialState = {
  chat: false,
  userProfile: false,
  notification: false,
}

export const ContextProvider = ({ children }) => {

  const [activeMenu, setActiveMenu] = useState(true);
  const [isClicked, setIsClicked] = useState(initialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [isLogin, setIsLogin] = useState(false);

  const handleClick = (clicked) => { 
    setIsClicked({ ...initialState, [clicked]: true });
  };

  const handleLogOut = () => {
    localStorage.removeItem('admin');
    setIsLogin(false);
  }

  return (
    <StateContext.Provider
      value={{
        initialState,
        activeMenu, setActiveMenu,
        isClicked, setIsClicked,
        handleClick,
        screenSize, setScreenSize,
        isLogin, setIsLogin,
        handleLogOut
      }}>
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext);