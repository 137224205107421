import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './UserProfile.css'; // Import the CSS file
import finalUrl from '../../../config/config';

function UserProfile() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = finalUrl+`/user?UserID=${userId}`;
        const response = await axios.get(url);
        setUserData(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Error fetching user data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return (
    <div className="user-profile-container">
      <h2 className="profile-heading">User Profile</h2>
      {loading && <p className="loading-message">Loading user data...</p>}
      {error && <p className="error-message">{error}</p>}
      {userData && (
        <ul className="profile-list">
          <li className="profile-item">UserID: {userData.UID}</li>
          <li className="profile-item">FirstName: {userData.FirstName}</li>
          <li className="profile-item">MidName: {userData.MidName}</li>
          <li className="profile-item">LastName: {userData.LastName}</li>
          <li className="profile-item">Gender: {userData.Gender}</li>
          <li className="profile-item">Birthday: {userData.Birthday}</li>
          <li className="profile-item">Email: {userData.Email}</li>
          <li className="profile-item">Phone: {userData.Phone}</li>
          <li className="profile-item">Address: {userData.Address}</li>
          <li className="profile-item">City: {userData.City}</li>
          <li className="profile-item">State: {userData.State}</li>
          <li className="profile-item">Country: {userData.Country}</li>
          <li className="profile-item">GithubID: {userData.GithubID}</li>
          <li className="profile-item">LinkedInID: {userData.LinkedInID}</li>
          <li className="profile-item">RoleID: {userData.RoleID===3?"Intern":"Instructor"}</li>
          <li className="profile-item">RegisteredOn: {userData.RegisteredOn}</li>
          <li className="profile-item">Note: {userData.Note}</li>
          {/* Add more fields as needed */}
        </ul>
      )}
    </div>
  );
}

export default UserProfile;
