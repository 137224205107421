import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, LinearProgress, Tooltip, Typography } from '@mui/joy'
import { Button, OutlinedInput } from '@mui/material';
import { CiImageOn } from "react-icons/ci";
import { ConfirmationModal, TagsInput } from '../common/index.js';
import { toast } from 'react-toastify';
import { ImUpload } from "react-icons/im";
import finalUrl from '../../config/config.js';



const UploadProject = () => {

  const filePickedRef = useRef();
  const inputRefs = useRef([]);
  const navbarHeight = 60;

  const [formData, setFormData] = useState({ eid: '998', title: '', description: '', image: '', projectlink: '', tags: '' });
  const [imageFileUrl, setImageFileUrl] = useState(null);
  const [imageFileUploading, setImageFileUploading] = useState(false);
  const [isUnderNavbar, setIsUnderNavbar] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setImageFileUploading(true);
      const formimg = new FormData();
      formimg.append('directory', '/uploads/projects');
      formimg.append('file', e.target.files[0]);

      const response = await fetch(finalUrl + '/ftp/upload/', {
        method: 'POST',
        body: formimg,
      });
      const data = await response.json();
      // console.log(data['path']);
      const url = URL.createObjectURL(file);
      setImageFileUrl(url);
      setFormData({ ...formData, image: data['path'] });

      setTimeout(() => {
        setImageFileUploading(false);
      }, 2000);
    }
  };

  const clearFormDatas = () => {
    setFormData({ eid: '998', title: '', description: '', image: '', projectlink: '', tags: '' });
    setTags([]);
    setImageFileUrl(null);
  };

  // -------------------------------------------- Form Submit --------------------------------------------

  const handleSubmit = async (e) => {
    console.log(formData);
    setLoading(true);
    try {
      const url = finalUrl + '/projects';
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      console.log(res);

    //   if (res.ok) {
    //     const data = await res.json();
    //     console.log(data);
    //     clearFields();
    //     navigate("/login");
    //   }
    } catch (error) {
      console.error(error);
    }
    setTimeout(() => {
      toast.success('Project Uploaded Successfully');
      clearFormDatas();
      setLoading(false);
    }, 4000);

  };

  // ----------------------------------------------------------------------------------------------------

  const validateForm = () => {
    const { title, description, projectlink, image } = formData;
    return title && description && projectlink && image; // add the eid after testing
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setOpen(true);
    } else {
      toast.error('Please fill all the required fields');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const inputsUnderNavbar = inputRefs.current.some(input => {
        const rect = input.getBoundingClientRect();
        return rect.top < navbarHeight && rect.bottom > 0;
      });
      setIsUnderNavbar(inputsUnderNavbar);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleTagsChange = () => {
      setFormData({ ...formData, tags: tags });
    };

    handleTagsChange();
  }, [tags]);

  return (
    <div className='mx-5 my-6 pt-2 pb-6 px-6 flex flex-col justify-start gap-3 transition-all ease-in-out bg-white rounded-lg overflow-y-hidden'>
      <div className='mb-8'>
        <Typography variant='h1' fontSize={24} className='font-bold'>Upload Project</Typography>
        <span className='text-sm text-slate-400'>Please Provide All The Necessary Informations</span>
      </div>
      <hr className='text-slate-400' />

      <form className='flex flex-col justify-normal gap-4' onSubmit={handleOpenModal}>
        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-2 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Employee ID <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Login emplyee's id selected</span>
          </div>
          <span className='text-[16px] text-slate-500 col-span-5 row-span-2'>{formData.eid}</span>
        </div>
        <hr className='text-slate-400' />

        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-2 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Title <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Enter your Project Title</span>
          </div>
          <OutlinedInput required={true} name='title' value={formData.title} onChange={handleChange} className={`!text-sm !rounded-lg col-span-5 row-span-2 ${isUnderNavbar ? 'pointer-events-none opacity-5' : ''}`} size='small' inputRef={el => inputRefs.current[0] = el} />
        </div>
        <hr className='text-slate-400' />

        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-1 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Description <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Write a short note on your mensioned project</span>
          </div>
          <OutlinedInput required={true} multiline minRows={3} maxRows={3} name='description' value={formData.description} onChange={handleChange} className='!text-sm !rounded-lg col-span-5 row-span-2' size='small' />
        </div>
        <hr className='text-slate-400' />

        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-1 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Image <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Upload the screenshot of your project</span>
          </div>
          <div className='col-span-5 row-span-2 flex justify-center items-center w-full'>
            <input type="file" accept='image/*' onChange={handleImageChange} ref={filePickedRef} hidden />
            <div className="relative w-full h-36 self-center cursor-pointer border rounded-lg overflow-hidden hover:shadow-md " onClick={() => filePickedRef.current.click()}>
              {imageFileUploading ? (
                <div className="flex justify-center items-center w-full h-full px-10">
                  <LinearProgress
                    sx={{
                      "--LinearProgress-thickness": "10px"
                    }}
                  />
                </div>
              ) : imageFileUrl ? (
                <Tooltip title='Change Image' variant='soft' arrow placement='right'>
                  <img src={imageFileUrl} alt="User" className="p-1 rounded-lg w-full h-full object-contain" />
                </Tooltip>
              ) : (
                <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-40 flex flex-col justify-center items-center'>
                  <Typography variant='h1' fontSize={14} className='!text-white'>Upload Image</Typography>
                  <CiImageOn className='text-white text-4xl' />
                </div>
              )}
            </div>
          </div>
        </div>
        <hr className='text-slate-400' />

        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-2 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Project Link <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Provide the Github Repository link which contains your project</span>
          </div>
          <OutlinedInput required={true} type='url' name='projectlink' value={formData.projectlink} onChange={handleChange} className='!text-sm !rounded-lg col-span-5 row-span-2' size='small' />
        </div>
        <hr className='text-slate-400' />

        <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
          <div className='row-span-2 col-span-4'>
            <Typography variant='h2' fontSize={16} className='font-semibold'>Tags <span className='text-[16px] text-red-500'>*</span></Typography>
            <span className='text-sm text-slate-400'>Please enter relevant keywords to describe your content and press Enter to add</span>
          </div>

          <div className='col-span-5 row-span-2'>
            <TagsInput tags={tags} setTags={setTags} />
          </div>
        </div>
        <hr className='text-slate-400' />

        <div className='mt-5 w-full flex justify-center items-center'>
          <Button type='submit' variant='contained' color='primary' disabled={loading} className='!rounded-lg !text-sm !px-24 !py-3 !flex !justify-center !items-center gap-2'>
            {
              loading ? <CircularProgress size="sm" variant="solid" color='primary' /> : <ImUpload size={20} />
            }
            <span className='text-sm'>{loading ? <span className='text-blue-500'>Uploading...</span> : "Upload Project"}</span>
          </Button>
        </div>

        <ConfirmationModal open={open} setOpen={setOpen} onConfirm={handleSubmit} />
      </form >

    </div >
  )
}

export default UploadProject