import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './EmailSender.css';

function EmailApp() {
    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [attachments, setAttachments] = useState([]);

    const handleSendEmail = () => {
        // Prepare email data to send to the backend
        const emailData = {
            recipient,
            subject,
            body,
            attachments: attachments.map(file => file.name) // Sending only filenames for now
        };

        // Send emailData to the backend
        fetch('http://localhost:8000/send-email/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(emailData)
        })
        .then(response => response.json())
        .then(data => {
            console.log(data); // Log response from backend
            // Clear form after sending email
            setRecipient('');
            setSubject('');
            setBody('');
            setAttachments([]);
        })
        .catch(error => {
            console.error('Error:', error); // Log any errors
        });
    };

    const handleAttachmentChange = (e) => {
        const files = Array.from(e.target.files);
        setAttachments([...attachments, ...files]);
    };

    return (
        <div className="app">
            <h2>Email Sender</h2>
            <div className="email-form">
                <label>To:</label>
                <input type="email" value={recipient} onChange={(e) => setRecipient(e.target.value)} />

                <label>Subject:</label>
                <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />

                <label>Body:</label>
                <ReactQuill theme="snow" value={body} onChange={(value) => setBody(value)} />

                <label>Attachments:</label>
                <input type="file" multiple onChange={handleAttachmentChange} />

                {attachments.length > 0 && (
                    <div>
                        <p>Selected Attachments:</p>
                        <ul>
                            {attachments.map((file, index) => (
                                <li key={index}>{file.name}</li>
                            ))}
                        </ul>
                    </div>
                )}

                <button onClick={handleSendEmail}>Send Email</button>
            </div>
        </div>
    );
}

export default EmailApp;
