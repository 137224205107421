import {Person, ConnectWithoutContact, Settings, Help, Logout} from '@mui/icons-material';

export const UserProfileOptions = [
  {
    icon: <Person />,
    to: '/user/:userId',
    name: 'Profile',
  },
  {
    icon: <ConnectWithoutContact />,
    to: '/user/:userId',
    name: 'Contact Us'
  },
  {
    icon: <Settings />,
    to: '/user/:userId',
    name: 'Settings'
  },
  {
    icon: <Help />,
    to: '/user/:userId',
    name: 'Help'
  },
  {
    icon: <Logout />,
    to: '/user/:userId',
    name: 'Logout'
  },  
];