import React, { useEffect, useState } from 'react';
import { Badge, Modal, ModalClose, Sheet, Tooltip, Typography, Table } from '@mui/joy';
import { Box, Container, Switch } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { UserTrackSkeleton } from '../common/Skeletons';
import { NoDataFound } from '../common';
import finalUrl from '../../config/config';
import axios from 'axios';

const Working = () => {

  const [contactUsData, setContactUsData] = useState([]);
  const [message, setMessage] = useState([]);
  const [toggleIsDone, setToggleIsDone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [updateIsDone, setUpdateIsDone] = useState(false);

  const columns = [
    {
      field: 'id', headerClassName: "header", headerName: 'ID', width: 50, sortable: false, headerAlign: 'center', disableColumnMenu: true,
      renderCell: (params) => (
        <span className='flex justify-center items-center'>{params.value}</span>
      ),
    },
    {
      field: 'profile', headerClassName: "header", headerName: 'Profile', sortable: false, headerAlign: 'center', width: 100, disableColumnMenu: true,
      renderCell: (params) => (
        <Tooltip title="View Profile" arrow placement='right' variant='solid' color='primary'>
          <div onClick={() => handleOpenModal(params.row)} className='w-10 h-10 bg-blue-400 mx-auto flex justify-center items-center my-[5px] rounded-full font-semibold text-white hover:shadow-md cursor-pointer'>
            {params.value}
          </div>
        </Tooltip>
      ),
    },
    { field: 'name', headerClassName: "header", headerName: 'First Name', width: 120 },
    { field: 'email', headerClassName: "header", headerName: 'Email', width: 120 },
    { field: 'phone', headerClassName: "header", headerName: 'Phone', width: 100 },
    { field: 'subject', headerClassName: "header", headerName: 'Subject', width: 130 },
    { field: 'message', headerClassName: "header", headerName: 'Message', width: 120, flex: 1 },
    { field: 'inquiryDate', headerClassName: "header", headerName: 'Inquiry Date', width: 130 },
    {
      field: 'isDone', headerClassName: "header", headerName: 'Is Done', width: 120,
      renderCell: (params) => (
        <div className='w-full h-full flex justify-start items-center'>
          <div className={`flex justify-center items-center gap-3 border pl-3 pr-2 ${params.value ? "border-green-400" : "border-red-400"} rounded-2xl`}>
            <Badge color={params.value ? "success" : "danger"} size="sm" />
            <Typography variant="caption" className={`${params.value ? "!text-green-400" : "!text-red-400"}`}>{params.value ? 'Done' : 'Pending'}</Typography>
          </div>
        </div >
      )
    },

  ];


  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const url = finalUrl + '/contactus';
        const response = await axios.get(url);
        setContactUsData(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, [updateIsDone]);

  const updatedContactUsData = contactUsData.map((user) => ({
    inqid: user.InquiryID,
    id: user.InquiryID,
    name: user.Name,
    email: user.Email,
    phone: user.Phone,
    subject: user.Subject,
    message: user.Message,
    inquiryDate: user.InquiryDate,
    isDone: user.isDone,
    profile: user.Name[0].toUpperCase(),
  }));

  const handleOpenModal = (data) => {
    setMessage(data);
    setToggleIsDone(data.isDone === 1);
    setOpenModal(true);
  }

  const handleCloseModel = () => {
    setMessage([]);
    setOpenModal(false);
  }

  const handleIsDone = (id) => {
    setContactUsData(contactUsData.map((item) => {
      if (item.InquiryID === id) {
        item.isDone = item.isDone === 1 ? 0 : 1;
      }
      return item;
    }));
    setToggleIsDone(contactUsData.find((item) => item.InquiryID === id).isDone === 1);
    //  Update the isDone status in the database 
      // try {
      //   const res = await fetch("http://localhost:8080/api/contacts/" + id, {
      //     method: 'PUT',
      //     headers: {
      //       'Content-Type': 'application/json',
      //     },
      //   });

      //   if (res) {
      //     const data = await res.json();
      //     setUpdateIsDone(data);
      //     setToggleIsDone(!toggleIsDone);
      //     setPageIdx(pageIndex);
      //     setPageIndex(pageIndex);
      //   }
      // } catch (error) {
      //   console.log(error);
      // }
  }

  const tableFields = [
    { id: 'inqid', name: 'Inquiry ID' },
    { id: 'name', name: 'Name' },
    { id: 'email', name: 'Email' },
    { id: 'phone', name: 'Phone' },
    { id: 'subject', name: 'Subject' },
    { id: 'message', name: 'Message' },
    { id: 'inquiryDate', name: 'Inquiry Date' },
  ];
  
  return (
    <div className='mx-5 my-6 pt-2 pb-6 flex flex-col justify-start gap-3 relative transition-all ease-in-out duration-500 bg-white rounded-lg overflow-y-hidden min-w-[calc(100vw-18.5rem)] min-h-screen'>
      <div className='mb-4 px-6'>
        <Typography variant='h1' fontSize={24} className='font-bold'>Contact Us Data</Typography>
        <span className='text-sm text-slate-400'>List of all messages recieved by Contact Us page.</span>
      </div>
      <div className='absolute top-24 right-0 bottom-0 left-0'>
        <Container className='w-full'>
          <Box sx={{ height: 490, width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <DataGrid
              disableAutosize
              rows={updatedContactUsData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30]}
              slots={{
                noRowsOverlay: () => (
                  loading ? <UserTrackSkeleton /> : updatedContactUsData.length === 0 ? <NoDataFound /> : null
                ),
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}
              disableColumnResize
              sx={{
                '& .header': {
                  backgroundColor: '#0ea5e9',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '15px'
                },
              }
              }
            />
          </Box>
        </Container>
      </div>

      {
        message && (
          <Modal variant="plain" open={openModal} onClose={() => handleCloseModel()} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <Sheet variant="plain" sx={{ width: 700, borderRadius: 'md', p: 3 }} >
              <ModalClose />
              <Typography component="h1" id="modal-title" level="h1" textColor="inherit" fontWeight="bold" fontSize={20} mb={1} >
                Message Details
              </Typography>

              <Table variant='outlined' >
                <thead>
                  <tr>
                    <th style={{ width: '25%', paddingLeft: "20px" }}>#</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    tableFields.map((field) => (
                      <tr key={field.id}>
                        <th scope='row' className='w-full !bg-slate-200 !pl-5 font-bold text-[15px]'>{field.name}</th>
                        <td className='!pl-4'>{message[field.id]}</td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td className='w-full !bg-slate-200 !pl-5 font-semibold text-[15px]'>Is Done</td>
                    <td className='!pl-4'>
                      <div className='flex justify-start items-center gap-3'>
                        <span className='text-[15px]'>{toggleIsDone ? 'Yes' : 'No '}</span>
                        <Switch checked={toggleIsDone} color='success' onClick={() => handleIsDone(message.inqid)} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Sheet>
          </Modal>
        )}
    </div>
  );
}

export default Working;
