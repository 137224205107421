import React, { useEffect, useState } from 'react'
import { Typography } from '@mui/joy';
import { useStateContext } from '../context/ContextProvider';
import { TracksSkeleton } from "../common/Skeletons/index";
import finalUrl from '../../config/config';
import { TracksCard } from '../common/Cards/index';

const Tracks = () => {
  const { activeMenu } = useStateContext();

  const [loading, setLoading] = useState(true);
  const [tracksList, setTracksList] = useState([]);

  useEffect(() => {
    const fetchTracks = async () => {
      try {
        const url = finalUrl + "/tracks";
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          }
        });

        if (res.ok) {
          const data = await res.json();
          setTracksList(data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    }

    fetchTracks();
  }, []);

  return (
    <div className='mx-5 my-6 pt-2 pb-6 px-6 flex flex-col justify-start gap-3 transition-all ease-in-out bg-white rounded-lg overflow-y-hidden min-h-[calc(100vh-7rem)]'>
      <div className='mb-4'>
        <Typography variant='h1' fontSize={24} className='font-bold'>View All Tracks</Typography>
        <span className='text-sm text-slate-400'>List of all tracks that has been awarded to interns.</span>
      </div>

      <div className={`${activeMenu ? "pl-3" : "pr-4"} transition-all ease-in-out duration-500`}>
        <div className={`grid ${activeMenu ? "grid-cols-3" : "grid-cols-4"} mx-auto gap-y-10 gap-x-10 justify-center items-center`}>
          {
            loading ? (
              <TracksSkeleton count={activeMenu ? 3 : 4} />
            ) : (
              tracksList.map((item) => (
                <TracksCard key={item.eid} data={item} />
              ))
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Tracks