import React from 'react';
import { RxCross2 } from "react-icons/rx";

const TagsInput = ({ tags, setTags }) => {

  const handleKeyDown = (e) => {
    if (e.key !== 'Enter') return

    const value = e.target.value
    if (!value.trim()) return

    setTags([...tags, value])
    e.target.value = '';

    e.preventDefault();
  }

  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index))
  }

  return (
    <div className="flex align-middle flex-wrap gap-2 border-1 p-2 rounded-lg">
      {
        tags.map((tag, index) => (
          <div className="inline-block py-1 px-3 bg-blue-100 rounded-lg" key={index}>
            <div className='flex justify-center items-center gap-2'>
              <span className="text-sm">{tag}</span>
              <span className="close text-red-400 cursor-pointer" onClick={() => removeTag(index)}>
                <RxCross2 />
              </span>
            </div>
          </div>
        ))
      }
      <input onKeyDown={handleKeyDown} type="text" className="flex-grow-1 py-1 border-none outline-none text-base" placeholder="Type somthing" />
    </div>
  )
}

export default TagsInput