import React, { useState } from 'react';
import finalUrl from '../../config/config';
import { districtsOfKarnataka } from '../../config/config';
import { Logo, RegisterBG } from '../assets';
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import { Link, useNavigate } from 'react-router-dom';

const Registration = () => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    FirstName: "",
    MiddleName: "",
    LastName: "",
    Gender: "",
    Birthday: "",
    Email: "",
    Phone: "",
    Address: "",
    City: "",
    Github: "",
    LinkedIn: "",
    More: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCityChange = (event, value) => {
    const cityIndex = districtsOfKarnataka.indexOf(value);
    setFormData({ ...formData, City: cityIndex });
  };

  const clearFields = () => {
    setFormData({
      FirstName: "",
      MiddleName: "",
      LastName: "",
      Gender: "",
      Birthday: "",
      Email: "",
      Phone: "",
      Address: "",
      City: "",
      Github: "",
      LinkedIn: "",
      More: "",
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    clearFields();

    try {
      const url = finalUrl + '/users';
    //   const res = await fetch(url, {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(formData),
    //   });

    //   if (res.ok) {
    //     const data = await res.json();
    //     console.log(data);
    //     clearFields();
    //     navigate("/login");
    //   }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className='py-4 h-full'>
        <div className='bg-white rounded-lg shadow-xl flex-col justify-start items-center'>
          <div className="flex justify-center items-center gap-4">
            <div className='px-4 py-6'>
              <div className='ml-6 flex flex-col justify-center items-center gap-3'>
                <div className='top-0 flex justify-start items-center gap-2 mx-auto'>
                  <img src={Logo} alt="Vrisharya Tech" width={40} height={40} />
                  <span className='font-bold text-2xl text-blue-600'>Vrisharya Tech.</span>
                </div>

                <div>
                  <h2 className="text-2xl font-semibold text-center mb-4">Registration</h2>
                  <p className="text-gray-600 text-center mb-6">Hello! Please fill in the form below.</p>
                </div>
              </div>
              <form className='ml-9' onSubmit={handleSubmit}>
                <div className='mb-5 grid grid-cols-2 gap-4'>
                  <TextField size='small' type='text' label="First Name" variant="outlined" name='FirstName' value={formData.FirstName} required={true} onChange={handleChange} />
                  <TextField size='small' type='text' label="Middle Name" variant="outlined" name='MiddleName' value={formData.MiddleName} onChange={handleChange} />
                </div>
                <div className='mb-5'>
                  <TextField size='small' type='text' label="Last Name" variant="outlined" name='LastName' value={formData.LastName} required={true} onChange={handleChange} />
                </div>
                <div className='mb-5 grid grid-cols-2 gap-4'>
                  <FormControl fullWidth size='small'>
                    <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                    <Select labelId="demo-simple-select-label" name='Gender' value={formData.Gender} label="Gender" required={true} onChange={handleChange} >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>

                  <TextField size='small' type='date' label="Birthday" variant="outlined" name='Birthday' value={formData.Birthday} required={true} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                </div>
                <div className='mb-5'>
                  <TextField size='small' type='email' label="Email" variant="outlined" name='Email' value={formData.Email} required={true} onChange={handleChange} className='w-3/4' />
                </div>
                <div className='mb-5 grid grid-cols-2 gap-4'>
                  <TextField size='small' type='number' label="Phone" variant="outlined" name='Phone' value={formData.Phone} required={true} onChange={handleChange} />

                  <Autocomplete
                    size='small'
                    disablePortal
                    id="combo-box-demo"
                    options={districtsOfKarnataka}
                    renderInput={(params) => <TextField {...params} label="City" />}
                    value={districtsOfKarnataka[formData.City]}
                    onChange={handleCityChange}
                  />
                </div>
                <div className='mb-5'>
                  <TextField size='small' type='text' multiline rows={2} label="Address" variant="outlined" name='Address' value={formData.Address} required={true} onChange={handleChange} className='w-full' />
                </div>
                <div className='mb-5 flex flex-col gap-5'>
                  <TextField size='small' type='text' label="Github Profile" variant="outlined" name='Github' value={formData.Github} required={true} onChange={handleChange} className='w-full' />
                  <TextField size='small' type='text' label="LinkedIn Profile" variant="outlined" name='LinkedIn' value={formData.LinkedIn} required={true} onChange={handleChange} className='w-full' />
                </div>
                <div className='mb-6'>
                  <TextField size='small' type='text' multiline rows={2} label="More" variant="outlined" name='More' value={formData.More} onChange={handleChange} className='w-full' />
                </div>

                <div className='mb-6 flex justify-center items-center w-full'>
                  <button type="submit" className="w-3/4 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Register</button>
                </div>
              </form>

              <div className='ml-9'>
                <p className="text-gray-600 text-[13px] text-center">
                  Already have an account yet?&nbsp;
                  <Link to="/login" className="text-[13px] text-blue-500 hover:underline text-center">Login</Link>.
                </p>
              </div>
            </div>

            <div>
              <img src={RegisterBG} alt="Background" className='rounded-r-lg !h-[55rem] !w-[30rem]' />
            </div>
          </div>

        </div>
      </div >
    </>

  );
}

export default Registration;