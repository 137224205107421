import React, { useState } from 'react'
import { Button, Typography } from '@mui/joy'
import EvaluatorList from './EvaluatorList'
import { Add } from '@mui/icons-material'

const EvaluatorPage = () => {

  const [isFormOpen, setIsFormOpen] = useState(true);

  return (
    <div className='mx-5 my-6 pt-2 pb-6 px-6 flex flex-col justify-start gap-3 transition-all ease-in-out bg-white rounded-lg overflow-y-hidden min-h-[calc(100vh-7rem)]'>
      <div className='mb-4'>
        <Typography variant='h1' fontSize={24} className='font-bold'>All Evaluators</Typography>
        <span className='text-sm text-slate-400'>List of all evaluators who can evaluate the interns.</span>
      </div>

      <div>
        <div className='flex flex-col justify-start w-full gap-5'>
          <div className='flex justify-end items-center mr-9'>
            <Button type='button' onClick={() => setIsFormOpen(true)} variant='soft' startDecorator={<Add />} className='!bg-[#3b82f6] !text-white hover:!bg-[#2563eb]'>Create Evaluation</Button>
          </div>
          <div className={`transition-opacity duration-500 ease-in-out mt-4 rounded-lg ${isFormOpen ? 'opacity-100 shadow-md' : 'opacity-0'
            } ${isFormOpen ? 'max-h-screen' : 'max-h-0'} overflow-hidden bg-white mx-10`}>
            <EvaluatorList formOpen={isFormOpen} setFormOpen={setIsFormOpen} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default EvaluatorPage