import { Typography } from '@mui/joy';
import React from 'react';
import { TopDashCards } from './pages/dashboard/index';
function Home() {
    return (
        <div className='mx-5 my-6 pt-2 pb-6 px-6 transition-all ease-in-out bg-white rounded-lg overflow-hidden h-[calc(100vh-7rem)]'>
            <Typography variant='h1' fontSize={24} mb={2} className='font-bold'>Home</Typography>

            <div className='flex flex-col justify-center items-center gap-4'>
                <TopDashCards />
            </div>
        </div>
    );
}

export default Home;