import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../context/ContextProvider';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoginBG, Logo } from '../assets';
import { FcGoogle } from 'react-icons/fc';
import { FaGithub } from 'react-icons/fa';

const Login = () => {

  const { setIsLogin } = useStateContext();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({ Email: '', Password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem('admin', formData.Email);
    setIsLogin(true);
    navigate("/");
  }

  const handleInputchange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <div className='py-4 h-screen flex justify-center items-center realtive'>
        <div className='bg-white rounded-lg shadow-xl flex-col justify-start items-center'>
          <div className="flex justify-center items-center gap-4">
            <div className='px-3 py-4 w-96'>
              <div className='mx-5 flex flex-col justify-center items-center gap-3'>
                <div className=' top-0 flex justify-start items-center gap-2'>
                  <img src={Logo} alt="Vrisharya Tech" width={40} height={40} />
                  <span className='font-bold text-2xl text-blue-600'>Vrisharya Tech.</span>
                </div>

                <div>
                  <h2 className="text-2xl font-semibold text-center mb-4">Login</h2>
                  <p className="text-gray-600 text-center mb-6">Hello! Please fill in the form below.</p>
                </div>
              </div>
              <form className='mx-8' onSubmit={handleSubmit}>
                <div className='mb-6'>
                  <TextField size='small' type='email' label="Email" variant="outlined" name='Email' value={formData.Email} required={true} onChange={handleInputchange} className='w-full' />
                </div>
                <div className='mb-6'>
                  {/* <TextField size='small' type='password' label="Password" variant="outlined" name='Password' value={formData.Password} required={true} onChange={handleInputchange} className='w-full' /> */}
                  <FormControl size='small' variant="outlined" className='w-full'>
                    <InputLabel htmlFor="outlined-adornment-password" required={true}>Password</InputLabel>
                    <OutlinedInput
                      required={true}
                      name='Password'
                      value={formData.Password}
                      onChange={handleInputchange}
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                  <div className='flex justify-end my-2'>
                    <Link to="/forgotpassword" className="text-[13px] text-blue-500 hover:underline">Forgot Password?</Link>
                  </div>
                </div>
                <div className='mb-6'>
                  <button type="submit" className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Login</button>
                </div>
              </form>

              <div className="grid grid-cols-3 items-center mx-8 text-gray-400">
                <hr className="border-gray-400" />
                <p className="text-center text-sm">OR</p>
                <hr className="border-gray-400" />
              </div>

              <div className='flex flex-row justify-center items-center mx-8 my-4 gap-5'>
                <Link to="/googleLogin" className='bg-white text-blue-700 flex justify-center items-center gap-3 hover:bg-blue-400 hover:text-white px-3 py-2 rounded shadow-lg transition-all ease-in-out duration-500'>
                  <FcGoogle className='w-6 h-6' />
                  <span className='font-semibold text-lg'>Google</span>
                </Link>
                <Link to="/githubLogin" className='bg-white text-blue-700 flex justify-center items-center gap-3 hover:bg-blue-400 hover:text-white px-3 py-2 rounded shadow-lg transition-all ease-in-out duration-500'>
                  <FaGithub className='w-6 h-6' />
                  <span className='font-semibold text-lg'>GitHub</span>
                </Link>
              </div>

              <div className='ml-9'>
                <p className="text-gray-600 text-[13px] mt-4 text-center">
                  Don't have an account yet?&nbsp;
                  <Link to="/registration" className="text-[13px] text-blue-500 hover:underline text-center">Register</Link>.
                </p>
              </div>
            </div>

            <div>
              <img src={LoginBG} alt="Background" className='rounded-r-lg !h-[32.5rem]' />
            </div>
          </div>

        </div>
      </div >
    </>
  )
}

export default Login;