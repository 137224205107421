import React, { useEffect, useState } from 'react'
import { AddTask, Clear } from '@mui/icons-material';
import { Typography, Button } from '@mui/joy';
import { MdMoreVert } from "react-icons/md";
import { Checkbox, FormControl, IconButton, InputLabel, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, OutlinedInput, Select } from '@mui/material';

const evaluatorsList = [
  { id: 1, name: 'John Doe' },
  { id: 2, name: 'Devid' },
  { id: 3, name: 'Smith' },
  { id: 4, name: 'Alex' },
  { id: 5, name: 'Steve' },
  { id: 6, name: 'John' },
]

const EvaluatorList = ({ formOpen, setFormOpen }) => {

  const [evalEventForm, setEvalEventForm] = useState({ id: '', date: '', noOfEvaluators: '', evaluator: '' });
  const [evaluators, setEvaluators] = useState([]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleItem = (itemName) => {
    if (evaluators.length < evalEventForm.noOfEvaluators) {
      setEvaluators((prevSelectedItems) =>
        prevSelectedItems.includes(itemName)
          ? prevSelectedItems.filter((item) => item !== itemName)
          : [...prevSelectedItems, itemName]
      );
    } else {
      if (evaluators.includes(itemName)) {
        setEvaluators((prevSelectedItems) => prevSelectedItems.filter((item) => item !== itemName));
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEvalEventForm({ ...evalEventForm, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedForm = {
      ...evalEventForm,
      evaluators: [...evaluators]
    };
    console.log(updatedForm);
  };

  const handleCloseForm = () => {
    setFormOpen(false);
    setEvalEventForm({ id: '', date: '', noOfEvaluators: '', evaluator: '' });
    setEvaluators([]);
  };

  useEffect(() => {
    const generateRandomId = () => {
      const timestamp = Date.now();
      const randomNum = Math.floor(Math.random() * 10000);
      setEvalEventForm({ ...evalEventForm, id: `${timestamp}_${randomNum}`, date: new Date().toUTCString() });
    };

    generateRandomId();
  }, [formOpen]);

  return (
    <form className='p-4 flex flex-col justify-normal gap-4' onSubmit={handleSubmit}>
      <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
        <div className='row-span-2 col-span-5'>
          <Typography variant='h2' fontSize={16} className='font-semibold'>Evaluation Id <span className='text-[16px] text-red-500'>*</span></Typography>
          <span className='text-sm text-slate-400'>This id is randomly generate and unique.</span>
        </div>
        <OutlinedInput disabled={true} required={true} name='id' value={evalEventForm.id} onChange={handleInputChange} className="!text-sm !rounded-lg col-span-5 row-span-2" size='small' />
      </div>
      <hr className='text-slate-400 mx-9' />

      <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
        <div className='row-span-2 col-span-5'>
          <Typography variant='h2' fontSize={16} className='font-semibold'>Date <span className='text-[16px] text-red-500'>*</span></Typography>
          <span className='text-sm text-slate-400'>Current date and time.</span>
        </div>
        <OutlinedInput disabled={true} required={true} name='date' value={evalEventForm.date} onChange={handleInputChange} className="!text-sm !rounded-lg col-span-5 row-span-2" size='small' />
      </div>
      <hr className='text-slate-400 mx-9' />

      <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
        <div className='row-span-2 col-span-5'>
          <Typography variant='h2' fontSize={16} className='font-semibold'>No. of Evaluators <span className='text-[16px] text-red-500'>*</span></Typography>
          <span className='text-sm text-slate-400'>Choose the number of evaluators for the evaluation.</span>
        </div>
        <FormControl size='small' className='col-span-2 row-span-2'>
          <InputLabel id="noOfEval">Evaluators</InputLabel>
          <Select labelId="noOfEval" value={evalEventForm.noOfEvaluators} label="Evaluators" name='noOfEvaluators' onChange={handleInputChange} >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
          </Select>
        </FormControl>
      </div>
      <hr className='text-slate-400 mx-9' />

      <div className='ml-9 grid grid-cols-12 justify-center items-center gap-4'>
        <div className='row-span-1 col-span-5'>
          <Typography variant='h2' fontSize={16} className='font-semibold'>Evaluators <span className='text-[16px] text-red-500'>*</span></Typography>
          <span className='text-sm text-slate-400'>Select the evaluators for the evaluation.</span>
        </div>
        <div className="col-span-5 flex">
          <div className='col-span-5 flex flex-wrap justify-normal items-center gap-3 border p-2 rounded-lg border-gray-400 min-w-full min-h-16'>
            {evaluators.map((item) => (
              <div key={item} className='flex justify-start items-center border w-fit pl-3 rounded-3xl border-blue-400'>
                <Typography variant='h2' fontSize={15} className='font-semibold'>{item}</Typography>
                <IconButton color='error' onClick={() => setEvaluators((prevSelectedItems) => prevSelectedItems.filter((evaluator) => evaluator !== item))}>
                  <Clear color='error' />
                </IconButton>
              </div>
            ))}
          </div>
          <div>
            <IconButton onClick={handleClick}>
              <MdMoreVert />
            </IconButton>
            <div className='ml-4'>
              {evaluatorsList && open && (
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ marginLeft: 5 }}>
                  {evaluatorsList.map((item) => (
                    <ListItem key={item.id} button={true} onClick={() => handleToggleItem(item.name)} sx={{ padding: "0px 15px" }}>
                      <ListItemIcon>
                        <Checkbox
                          // edge="start"
                          checked={evaluators.includes(item.name)}
                          tabIndex={-1}
                          disableRipple
                        />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </Menu>
              )}
            </div>
          </div>
        </div>
      </div>
      <hr className='text-slate-400 mx-9' />

      <div className='flex justify-start items-center gap-4 mx-9 my-5'>
        <Button type='submit' variant='soft' startDecorator={<AddTask />} className='!bg-[#10b981] !text-white hover:!bg-[#059669]'>Create</Button>
        <Button type='button' onClick={handleCloseForm} variant='soft' startDecorator={<Clear />} className='!bg-[#ef4444] !text-white hover:!bg-[#dc2626]'>Cancel</Button>
      </div>

    </form >
  )
}

export default EvaluatorList