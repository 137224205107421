import React, { useEffect, useState } from 'react';
import { Dropdown, ListDivider, Menu, MenuButton, MenuItem, Tooltip } from '@mui/joy';
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { useStateContext } from '../context/ContextProvider';
import { MdKeyboardArrowDown, MdOutlineMarkChatUnread } from "react-icons/md";
import { RiNotification3Line } from 'react-icons/ri';
import { IoSettingsOutline } from 'react-icons/io5';
import { Avatar, Logo } from '../assets';
import { UserProfileOptions } from '../data/UserProfileOptions';
import { Link, useNavigate } from 'react-router-dom';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <Tooltip title={title} placement='bottom' arrow>
    <button type='button' onClick={customFunc} style={{ color }} className='relative text-xl rounded-full p-3 bg-transparent hover:bg-light-gray'>
      <span style={{ backgroundColor: dotColor }} className='absolute inline-flex rounded-full right-2 top-2' />
      {icon}
    </button>
  </Tooltip>
);

const Navbar = () => {

  const navigate = useNavigate();

  const [backdropStyle, setBackdropStyle] = useState(false);

  const {
    activeMenu, setActiveMenu,
    handleClick,
    screenSize, setScreenSize,
    handleLogOut
  } = useStateContext();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setBackdropStyle(true);
      } else {
        setBackdropStyle(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);

  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  }

  const handleClickProfileMenu = (val) => {
    if (val === "Logout") {
      handleLogOut();
      navigate("/login");
    }
  }

  return (
    <div className={`fixed w-full left-0 border-b-2 border-blue-400`}>
      <div className={`static flex justify-between py-3 px-3 ${backdropStyle ? "backdrop-blur-sm bg-[rgba(255,255,255,0.7)]" : "bg-white"}`}>
        <div className='flex justify-start items-center gap-3'>
          <div className='flex justify-between items-center'>
            <Link to="/" onClick={handleCloseSidebar} className='items-center gap-3 ml-3 flex'>
              <img src={Logo} alt="Vrisharya Technology" width={40} height={40} />
              <span className=' text-xl font-bold tracking-tight text-blue-500 whitespace-nowrap'>Vrisharya Tech.</span>
            </Link>
          </div>

          <NavButton
            title={activeMenu ? 'Close Sidebar' : 'Open Sidebar'}
            customFunc={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
            icon={activeMenu ? <BsArrowLeftCircle className='w-5 h-5' /> : <BsArrowRightCircle className='w-5 h-5' />}
            color='blue'
          />

        </div>

        <div className='flex'>
          <NavButton
            title="Chat"
            dotColor="#03C9D7"
            customFunc={() => handleClick("chat")}
            icon={<MdOutlineMarkChatUnread />}
            color='blue'
          />

          <NavButton
            title="Notifications"
            dotColor="#03C9D7"
            customFunc={() => handleClick("notification")}
            icon={<RiNotification3Line />}
            color='blue'
          />

          <NavButton
            title="Notifications"
            dotColor="#03C9D7"
            customFunc={() => handleClick("notification")}
            icon={<IoSettingsOutline />}
            color='blue'
          />

          <Tooltip title="Profile" placement='bottom' arrow>
            <Dropdown>
              <MenuButton sx={{ borderRadius: "50px", paddingLeft: "8px", paddingRight: "8px", ":hover": { backgroundColor: "#F7F7F7" }, border: "none" }}>
                <div className='flex items-center gap-2 cursor-pointer hover:bg-light-gray rounded-3xl' onClick={() => handleClick("userProfile")}>
                  <img src={Avatar} alt="Avatar" className='rounded-full w-8 h-8' />
                  <p>
                    <span className='text-gray-400 text-14'>Hi, </span>{' '}
                    <span className='text-gray-400 font-bold ml-1 text-14'>John Doe</span>
                  </p>
                  <MdKeyboardArrowDown className='text-gray-400 text-xl' />
                </div>
              </MenuButton>
              <Menu className='w-52' placement='bottom-start'>
                {
                  UserProfileOptions.map((item) => (
                    <div key={item.name}>
                      <MenuItem onClick={() => handleClickProfileMenu(item.name)}>
                        <div className='flex items-center gap-4 py-2 px-3'>
                          {item.icon}
                          <p className='text-14'>{item.name}</p>
                        </div>
                      </MenuItem>

                      {item.name === "Help" && <ListDivider />}
                    </div>
                  ))
                }
              </Menu>
            </Dropdown>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default Navbar