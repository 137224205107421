import { Skeleton } from '@mui/joy'
import React from 'react'

const UserTableSkeleton = () => {
  return (
    Array(5).fill().map((item, index) => (
      <>
        <div key={index} className='flex justify-center items-center gap-1 my-2'>
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="rectangular" width={180} height={40} />
          <Skeleton variant="rectangular" width={130} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={120} height={40} />
          <Skeleton variant="rectangular" width={130} height={40} />
          <Skeleton variant="rectangular" width={150} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
        </div>
        <hr />
      </>
    ))
  )
};

export default UserTableSkeleton;