import React, { useState, useEffect } from 'react'
import { Typography } from '@mui/joy'
import { ProjectCard } from '../common/index';
import finalUrl from '../../config/config';
import { useStateContext } from '../context/ContextProvider';
import { ProjectSkeleton } from '../common/Skeletons/index';

const ViewProjects = () => {

  const { activeMenu } = useStateContext();

  const [projectsData, setProjectsData] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(false);

  useEffect(() => {
    const fontProjects = async () => {
      setLoadingProjects(true);
      try {
        const url = finalUrl + "/projects";
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (res.ok) {
          const data = await res.json();
          setProjectsData(data);
        }
        setLoadingProjects(false);

      } catch (error) {
        console.log(error);
      }
    }

    fontProjects();
  }, []);

  return (
    <div className='mx-5 my-6 pt-2 pb-6 px-6 flex flex-col justify-start gap-3 transition-all ease-in-out bg-white rounded-lg overflow-y-hidden min-h-[calc(100vh-7rem)]'>
      <div className='mb-4'>
        <Typography variant='h1' fontSize={24} className='font-bold'>View All Projects</Typography>
        <span className='text-sm text-slate-400'>List of all projects that has been uploaded.</span>
      </div>

      <div className={`${activeMenu ? "pl-3" : "pr-4"} transition-all ease-in-out duration-500`}>
        <div className={`grid ${activeMenu ? "grid-cols-3" : "grid-cols-4"} mx-auto gap-y-10 gap-x-10 justify-center items-center`}>
          {
            loadingProjects ? (
              <ProjectSkeleton count={activeMenu ? 3 : 4} />
            ) : (
              projectsData.map((item) => (
                <ProjectCard key={item.eid} data={item} />
              ))
            )
          }
        </div>
      </div>
    </div>
  )
}

export default ViewProjects