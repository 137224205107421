import React from 'react';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { FaGithub, FaHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { EmptyProjectImg } from '../../assets';
import { Tooltip } from '@mui/joy';

const ProjectCard = ({ data }) => {
  return (
    <div>
      <Card sx={{ width: 300, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;" }}>
        <CardMedia
          sx={{ height: 150 }}
          image={data?.image || EmptyProjectImg}
          title={data?.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div"> {data?.title} </Typography>
          <Typography variant="body2" color="text.secondary"> {data?.description} </Typography>
          <div className='my-2'>
            {
              data?.tags.length !== 0 ? data?.tags.map((tag, index) => (
                <span key={index} className='text-sm cursor-pointer font-semibold bg-slate-100 text-blue-500 px-2 py-1 rounded-md mr-2'>#{tag}</span>
              )) : (
                <div className='h-6' />
              )
            }
          </div>
        </CardContent>
        <dic className='flex justify-center items-center gap-6 mb-5 mx-4'>
          <Tooltip title="Likes" arrow placement='left' variant="soft">
            <div className='flex justify-center items-center gap-2 w-20 shadow-md p-2  rounded-md'>
              <FaHeart className='w-6 h-6 text-red-600' />
              <span className='text-[15px] font-semibold text-slate-600'>{data?.likes}</span>
            </div>
          </Tooltip>
          <Tooltip title="Project Link" arrow placement='right' variant="soft">
            <div className='flex justify-center items-center gap-2 w-20 shadow-md p-2 rounded-md'>
              <Link to={data?.projectlink} target='_blank'><FaGithub className='w-6 h-6' /></Link>
            </div>
          </Tooltip>
        </dic>
      </Card>
    </div>
  )
}

export default ProjectCard