import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Tooltip, Typography } from '@mui/joy';
import { Box, Container } from '@mui/material';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { UserTrackSkeleton } from '../common/Skeletons';
import { NoDataFound } from '../common';
import finalUrl from '../../config/config';
import axios from 'axios';

const columns = [
  {
    field: 'profile', headerClassName: "header", headerName: 'Profile', sortable: false, headerAlign: 'center', width: 110, disableColumnMenu: true,
    renderCell: (params) => (
      <Tooltip title="View Profile" arrow placement='right' variant='solid' color='primary'>
        <Link to={`/user/${params.row.uid}`} className='w-10 h-10 bg-blue-400 mx-auto flex justify-center items-center my-[5px] rounded-full font-semibold text-white hover:shadow-md'>
          {params.value}
        </Link>
      </Tooltip>
    ),
  },
  { field: 'name', headerClassName: "header", headerName: 'Full Name', width: 180 },
  { field: 'id', headerClassName: "header", headerName: 'Employee ID', width: 130 },
  { field: 'email', headerClassName: "header", headerName: 'Email', width: 250 },
  {
    field: 'rating', headerClassName: "header", headerName: 'Rating', width: 150,
    renderCell: (params) => (
      <span className='ml-4'>{params.value}</span>
    )
  },
  {
    field: 'points', headerClassName: "header", headerName: 'Points', width: 150,
    renderCell: (params) => (
      <span className='ml-4'>{params.value}</span>
    )
  },
];

const TrackDetails = () => {

  const { trackId } = useParams();

  const [tracksUser, setTracksUser] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const url = finalUrl + `/tracks/${trackId}/employees`;
        const response = await axios.get(url);
        setTracksUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchUsers();

  }, []);

  const updatedTracskUserData = tracksUser.map((user) => ({
    eid: user.eid,
    id: user.eid,
    name: user.name,
    email: user.email,
    rating: user.rating,
    points: user.points,
    profile: user.name[0].toUpperCase(),
  }));

  return (
    <div className='mx-5 my-6 pt-2 pb-6 flex flex-col justify-start gap-3 relative transition-all ease-in-out duration-500 bg-white rounded-lg overflow-y-hidden min-w-[calc(100vw-18.5rem)] min-h-screen'>
      <div className='mb-4 px-6'>
        <Typography variant='h1' fontSize={24} className='font-bold'>{trackId === "1" ? "Flutter Development " : "Web Development "}Track</Typography>
        <span className='text-sm text-slate-400'>List of all interns those are learing this.</span>
      </div>
      <div className='flex justify-center items-center mx-auto'>
        <Container className='flex justify-center items-center mx-auto w-full'>
          <Box sx={{ height: 490, width: "100%", boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px" }}>
            <DataGrid
              disableAutosize
              rows={updatedTracskUserData}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10, 20, 30]}
              slots={{
                noRowsOverlay: () => (
                  loading ? <UserTrackSkeleton /> : updatedTracskUserData.length === 0 ? <NoDataFound /> : null
                ),
                toolbar: GridToolbar,
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                },
              }}

              disableColumnResize

              sx={{
                '& .header': {
                  backgroundColor: '#0ea5e9',
                  color: '#fff',
                  fontWeight: 'bold',
                  fontSize: '15px'
                },
              }
              }
            />
          </Box>
        </Container>

      </div>
    </div>
  );
}

export default TrackDetails;