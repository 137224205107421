import React from 'react';
import { NavLink } from 'react-router-dom';
import { SidenavLinks } from '../data/SidenavLinks';
import { useStateContext } from '../context/ContextProvider';

const Sidebar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-white text-md m-2";
  const normalLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 hover:bg-light-gray m-2";

  const handleCloseSidebar = () => {
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  }
  return (
    <div className='ml-3 h-screen overflow-auto md:hover:overflow-auto pb-10'>
      {
        activeMenu && (
          <>
            <div className="mt-6">
              {
                SidenavLinks.map((item) => (
                  <div key={item.title}>
                    <p className='text-gray-400 m-3 mt-4 uppercase text-[14px]'>
                      {item.title}
                    </p>
                    {item.links.map((link) => (
                      <NavLink key={link.name} to={link.to} onClick={handleCloseSidebar} style={({ isActive }) => ({ backgroundColor: isActive ? "#0ea5e9" : "" })} className={({ isActive }) => isActive ? activeLink : normalLink}>
                        {link.icon}
                        <span className='capitalize text-[14px]'>{link.name}</span>
                      </NavLink>
                    ))}
                  </div>
                ))
              }
            </div>
          </>
        )
      }
    </div>
  )
}

export default Sidebar