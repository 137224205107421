import React from 'react'
import { Typography } from '@mui/joy'
import { BsDatabaseFillSlash } from 'react-icons/bs'

const NoDataFound = () => {
  return (
    <div className='w-full h-full flex justify-center items-center'>
      <div className='flex justify-center items-center gap-3 my-16'>
        <BsDatabaseFillSlash fontSize="8rem" className='text-slate-300' />
        <Typography variant="caption" fontSize={20} className='text-slate-300'>No data found!</Typography>
      </div>
    </div>
  )
}

export default NoDataFound