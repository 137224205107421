import { Button, Modal, ModalClose, Sheet, Typography } from '@mui/joy'
import React from 'react';

const ConfirmationModal = ({ open, setOpen, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: 400,
          borderRadius: 'md',
          p: 3,
          boxShadow: 'lg',
        }}
      >
        <ModalClose variant="plain" sx={{ m: 1 }} />
        <Typography
          id="modal-title"
          level="h4"
          textColor="inherit"
          fontWeight="lg"
          mb={1}
        >
          Confirmation
        </Typography>
        <Typography textColor="text.tertiary">
          Are you sure you want to upload this project ?
        </Typography>
        <div className='flex w-full justify-end items-center mt-4'>
          <Button type='submit' onClick={() => { onConfirm(); setOpen(false) }} variant="soft" color="primary" sx={{ mt: 2 }}><span>Yes, Submit</span></Button>
          <Button type='button' onClick={() => setOpen(false)} variant="outlined" color="danger" sx={{ mt: 2, ml: 2 }}><span>Cancel</span></Button>
        </div>
      </Sheet>
    </Modal>
  )
}

export default ConfirmationModal