import React from 'react'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { Button } from '@mui/joy'
import { Link } from 'react-router-dom'
import { EmptyProjectImg } from '../../assets'

const TracksCard = ({ data }) => {
  return (
    <Link to={`/tracks/${data.id}/employees`}>
      <Card sx={{ width: 300, boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;" }}>
        <CardMedia
          sx={{ height: 150, margin: "8px", borderRadius: "5px" }}
          image={data?.image_url || EmptyProjectImg}
          title={data?.title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div"> {data?.name} </Typography>
          <Typography variant="body2" color="text.secondary"> {data?.description} </Typography>
        </CardContent>
        <div className='w-full flex justify-center mt-2 mb-4 px-4'>
          <Button variant='soft' className='bg-blue-400 w-full'>View More Details</Button>
        </div>
      </Card>
    </Link>
  )
}

export default TracksCard