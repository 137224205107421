import React from "react";
import { AspectRatio, Skeleton, Typography } from "@mui/joy";
import { portfolio1 } from "../../assets";

const TracksSkeleton = ({ count }) => {
  return (
    <>
      {
        Array(count).fill().map((item, index) => (
          <div className='w-[300px] p-4 rounded-lg shadow'>
            <AspectRatio ratio={16 / 9}>
              <Skeleton>
                <img src={portfolio1} alt="Web Development" className='rounded' />
              </Skeleton>
            </AspectRatio>
            <div>
              <Typography variant='h2' mt={1} fontSize={20} className='font-bold'><Skeleton>Web Development</Skeleton></Typography>
              <Typography variant='p' fontSize={14} className='text-gray-400'><Skeleton>Learn web development from scratch</Skeleton></Typography>
            </div>

            <div className='flex justify-center items-center gap-6 mt-5'>
              <Skeleton width={200} height={40} variant='rectangular' />
            </div>
          </div >
        ))
      }
    </>
  )
}

export default TracksSkeleton;