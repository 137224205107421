import { Skeleton } from '@mui/joy'
import React from 'react'

const EmployeeTableSkeleton = () => {
  return (
    Array(5).fill().map((item, index) => (
      <>
        <div key={index} className='flex justify-center items-center gap-2 my-2 mx-3'>
          <Skeleton variant="circular" width={50} height={50} sx={{marginLeft: "10px", marginRight: "10px"}} />
          <Skeleton variant="rectangular" width={120} height={40} />
          <Skeleton variant="rectangular" width={110} height={40} />
          <Skeleton variant="rectangular" width={110} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={70} height={40} />
          <Skeleton variant="rectangular" width={70} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={90} height={40} />
        </div>
        <hr />
      </>
    ))
  )
};

export default EmployeeTableSkeleton;