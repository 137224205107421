import './App.css';
import { Routes, Route, useNavigate } from 'react-router-dom';
import ContactDataDisplay from './components/pages/contactus/ContactUsData';
import Home from './components/Home';
import EmailSend from './components/pages/admin/EmailSender'
import UserProfile from './components/pages/users/UserProfile';
import UserToEmployee from './components/pages/employees/UserToEmployee'
import EmpTrack from './components/pages/employees/EmpTrack'
import { useStateContext } from './components/context/ContextProvider';
import { NotFound } from './components/common/index.js';
import {
  Login, Navbar, Registration, Sidebar, TrackDetails,
  Tracks, UploadProject, ViewProjects, UsersList, EmployeeList,
  EvaluatorPage
} from './components/pages/index.js';
import { useEffect } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AppBar } from '@mui/material';
import Working from './components/pages/Working.jsx';

const App = () => {

  const navigate = useNavigate();

  const { activeMenu, isLogin, setIsLogin } = useStateContext();

  useEffect(() => {
    const fetchLogin = async () => {
      const user = localStorage.getItem('admin');
      if (user) {
        setIsLogin(true);
      } else {
        setIsLogin(false);
        navigate('/login');
      }
    }

    fetchLogin();
  }, []);

  return (
    <>
      {
        !isLogin ? (
          <div className='bg-gradient-to-r from-blue-100 via-blue-300 to-blue-500 w-full flex justify-center items-center'>
            <Routes>
              <Route exact path='/login' element={<Login />} />
              <Route exact path='/registration' element={<Registration />} />
              <Route path='/*' element={<NotFound />} />
            </Routes>
          </div>
        ) : (
          <div className='flex relative overflow-hidden'>
            {
              activeMenu ? (
                <div className='mt-16 fixed sidebar bg-white transition-all ease-in-out duration-500 w-64'>
                  <Sidebar />
                </div>
              ) : (
                <div className='mt-16 w-0 sidebar bg-white transition-all ease-in-out duration-500'>
                  <Sidebar />
                </div>
              )
            }

            <div className={`bg-slate-50 min-h-screen w-full rounded-tl-lg ${activeMenu ? 'md:ml-64 ' : 'flex-2'}`}>
              <AppBar className={`bg-white navbar w-full shadow-lg z-100000000`}>
                <Navbar />
              </AppBar>

              <div className='pt-16'>
                <ToastContainer
                  position="bottom-right" autoClose={5000} hideProgressBar={false} newestOnTop={false}
                  closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover={false} theme="light"
                  transition={Slide}>
                </ToastContainer>

                <Routes>
                  <Route exact path='/' element={< Home />} />
                  <Route exact path="/email" element={<EmailSend />} />
                  <Route exact path='/users' element={<UsersList />} />
                  <Route path="/user/:userId" element={<UserProfile />} />
                  <Route exact path='/usrtoemp/:uid' element={< UserToEmployee />} />
                  <Route path="/employees" element={<EmployeeList />} />
                  <Route exact path='/trackselect/:uid' element={< EmpTrack />} />
                  <Route exact path='/contactus' element={<ContactDataDisplay />} />

                  <Route exact path='/upload-project' element={<UploadProject />} />
                  <Route exact path='/view-projects' element={<ViewProjects />} />
                  <Route exact path="/tracks" element={<Tracks />} />
                  <Route path='/tracks/:trackId/employees' element={<TrackDetails />} />

                  <Route path="/evaluation" element={<EvaluatorPage />} />
                  <Route path="/evaluation/:evaluatorId" element={<EvaluatorPage />} />

                  <Route path="/working" element={<Working />} />
                  <Route path='/*' element={<NotFound />} />
                </Routes>
              </div>
            </div>
          </div>
        )
      }
    </>
  );
}

export default App;
