import { AiOutlineAreaChart, AiOutlineBarChart, AiOutlineCalendar, AiOutlineStock } from "react-icons/ai";
import { BiColorFill } from "react-icons/bi";
import { BsBarChart, BsKanban } from "react-icons/bs";
import { FiEdit, FiPieChart } from "react-icons/fi";
import { GiLouvrePyramid } from "react-icons/gi";
import { IoMdContacts } from "react-icons/io";
import { RiContactsLine, RiStockLine } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { MdContacts, MdOutlineTrackChanges  } from "react-icons/md";
import { GoProjectSymlink } from "react-icons/go";
import { SiDocsdotrs, SiProgress  } from "react-icons/si";

export const SidenavLinks = [
  {
    title: 'Dashboard',
    links: [
      {
        name: 'home',
        icon: <RxDashboard />,
        to: '/',
      },
    ],
  },

  {
    title: 'Pages',
    links: [
      {
        name: "tracks",
        icon: <MdOutlineTrackChanges />,
        to: '/tracks',
      },
      {
        name: "Upload Project",
        icon: <GoProjectSymlink />,
        to: '/upload-project',
      },
      {
        name: 'employees',
        icon: <IoMdContacts />,
        to: '/employees',
      },
      {
        name: 'users',
        icon: <RiContactsLine />,
        to: '/users',
      },
      {
        name: "Projects",
        icon: <SiDocsdotrs />,
        to: "/view-projects"
      },
      {
        name: "Evaluation",
        icon: <SiProgress  />,
        to: "/evaluation"
      },
      {
        name: "Contact Us",
        icon: <MdContacts />,
        to: "/contactus"
      }
    ],
  },
  {
    title: 'Apps',
    links: [
      {
        name: 'calendar',
        icon: <AiOutlineCalendar />,
        to: '/calendar',
      },
      {
        name: 'kanban',
        icon: <BsKanban />,
        to: '/kanban',
      },
      {
        name: 'editor',
        icon: <FiEdit />,
        to: '/editor',
      },
      {
        name: 'color-picker',
        icon: <BiColorFill />,
        to: '/color-picker',
      },
    ],
  },
  {
    title: 'Charts',
    links: [
      {
        name: 'line',
        icon: <AiOutlineStock />,
        to: '/line',
      },
      {
        name: 'area',
        icon: <AiOutlineAreaChart />,
        to: '/area',
      },

      {
        name: 'bar',
        icon: <AiOutlineBarChart />,
        to: '/bar',
      },
      {
        name: 'pie',
        icon: <FiPieChart />,
        to: '/pie',
      },
      {
        name: 'financial',
        icon: <RiStockLine />,
        to: '/financial',
      },
      {
        name: 'color-mapping',
        icon: <BsBarChart />,
        to: '/color-mapping',
      },
      {
        name: 'pyramid',
        icon: <GiLouvrePyramid />,
        to: '/pyramid',
      },
      {
        name: 'stacked',
        icon: <AiOutlineBarChart />,
        to: '/stacked',
      },
    ],
  },
];